import React, { Component, useCallback, useEffect, useRef } from 'react';
import _ from 'lodash';

import * as L from "leaflet";
import MapHelper from "./../../../helpers/MapHelper";
import ZoomControl from "./ui/ZoomControl";
import MapTypeControl from "./ui/MapTypeControl";
import ShapesControl from "./ui/ShapesControl";
import MapSearchControl from "./ui/MapSearchControl";
import OnMapInfoPanel from "./ui/OnMapInfoPanel";
import MapWithMeasurement from './ui/MapWithMeasurement';


import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { useMap } from "../../../providers/MapProvider";
import ShapeStyling from "../shapes/ShapeStyling";
import {useApi} from "../../../providers/ApiProvider";
import UsersModel from "../../../models/UsersModel";
import CoordinatesModel from "../../../models/CoordinatesModel";
import MapSearchControlGetAddress from "./ui/MapSearchControlGetAddress";
import { useAuth } from "../../../providers/AuthProvider";
import UnionShape from "./UnionShape";
import ProjectsModel from "../../../models/ProjectsModel";
import Loading from "../../ui/Loading";
import ImportExportControl from "./ui/ImportExportControl";
import MapInfoPanel from "./ui/MapInfoPanel";
import ShapePanelControl from "../shapes/ShapePanelControl";
import {useParams} from "react-router-dom";
import ShapesListPanel from "../shapes/ShapesListPanel";
import ShapesModel from "../../../models/ShapesModel";
import {useNotifications} from "../../../providers/NotificationsProvider";
import UnitsToggle from "./ui/UnitsToggle";
import ImportFromFilesModal from "./ui/ImportFromFilesModal";
import EmailReportModal from "./ui/EmailReportModal";
import {Tooltip} from "react-tooltip";
import SimpleModal from "../../ui/SimpleModal";


export default function Map({ center, mapType, project, hideToolbar = false }) {
    const mapContext = useMap();
    const apiContext = useApi();
    const shapesModel = new ShapesModel(apiContext.api);
    const {user} = useAuth();
    const notifications = useNotifications();
    const {projectId} = useParams();

    const [projectInfo, setProjectInfo] = React.useState({ title: '', description: '' });
    const [curCenter, setCurCenter] = React.useState(center ? center : MapHelper.getDefaultCenter());
    const [selectedMapType, setSelectedMapType] = React.useState(mapType ? mapType : MapHelper.defaultMapType);
    const [map, setMap] = React.useState(null);
    const [mapLayers, setMapLayers] = React.useState({});
    const [shapes, setShapes] = React.useState({});
    const [allShapesShown, setAllShapesShown] = React.useState(false);
    const [infoPanelsMinified, setInfoPanelsMinified] = React.useState(false);
    let unionShape = useRef({});
    const [locations, setLocations] = React.useState({ positive: [], negative: [], stats: {total: 0, positive: 0, negative: 0} });
    const locationsLoading = React.useRef([]);
    const [cursorPosition, setCursorPosition] = React.useState({ lat: 0, lng: 0 });
    const [rulerActive, setRulerActive] = React.useState(0);
    const [emailReportModalShown, setEmailReportModalShown] = React.useState(false);
    const [locationFilters, setLocationFilters] = React.useState([1, 2, 4]);
    const [purchaseOrderModalShown, setPurchaseOrderModalShown] = React.useState(false);
    const [purchaseOrder, setPurchaseOrder] = React.useState('');

    const mapRef = React.useRef(null);
    const mapSearchedLocationRef = React.useRef(null);

    const coordinatesModel = new CoordinatesModel(apiContext.api);
    const projectsModel = new ProjectsModel(apiContext.api);


    useEffect(() => {
        if (!mapContext.isInitialized) {
            const mapElements = MapHelper.initMap(mapRef.current, curCenter);

            mapContext.init(mapElements.map);
            setMapLayers(mapElements.layers);

            if (project && project.data && project.data.shapes) {
                setShapes(project.data.shapes);

                unionShape.current = new UnionShape(project.data.shapes);

                if (unionShape.current && unionShape.current.bounds && unionShape.current.bounds.isValid() && mapContext.map) {
                    mapContext.map.fitBounds(unionShape.current.bounds, {paddingTopLeft: [64, 160], paddingBottomRight: [64, 64]});
                }
                onShapesChanged(project.data.shapes, true, false);
            }

            if (project && project.versionParameters) {
                if (project.versionParameters.map_type && (selectedMapType !== project.versionParameters.map_type)) {
                    setSelectedMapType(project.versionParameters.map_type);
                }
                if (project.versionParameters.location_filters && (locationFilters !== project.versionParameters.location_filters)) {
                    setLocationFilters(project.versionParameters.location_filters);
                }
            }

            setProjectInfo({
                title: project.title,
                description: project.description
            });
        }
    }, [project]);


    useEffect(() => {
        if (mapContext.isInitialized) {
            setMap(mapContext.map);

            if (unionShape.current && unionShape.current.bounds && unionShape.current.bounds.isValid()) {
                mapContext.map.fitBounds(unionShape.current.bounds, {paddingTopLeft: [64, 160], paddingBottomRight: [64, 64]});
            } else {
                const tmpUnionShape = new UnionShape(project.data.shapes);

                if (tmpUnionShape && tmpUnionShape.bounds && tmpUnionShape.bounds.isValid()) {
                    mapContext.map.fitBounds(tmpUnionShape.bounds, {paddingTopLeft: [64, 160], paddingBottomRight: [64, 64]});
                }
            }
        }
    }, [mapContext.isInitialized]);


    useEffect(() => {
        if (map && mapLayers[selectedMapType]) {
            mapLayers[selectedMapType].addTo(map);
            if (['OSMStandard', 'esriWorldImagery', 'esriTopographic'].includes(selectedMapType)) {
                if (map.getZoom() > 19) {
                    map.setZoom(19);
                }
                map.setMaxZoom(19);
            } else {
                map.setMaxZoom(MapHelper.defaultMaxZoom);
            }

            Object.keys(mapLayers).forEach((key) => {
                if (key !== selectedMapType) {
                    map.removeLayer(mapLayers[key]);
                }
            });

            map.on('mousemove', onMouseMove);
        }
    }, [map, mapLayers, selectedMapType]);


    useEffect(() => {
        refreshLocations(shapes);
    }, [locationFilters]);


    useEffect(() => {
        if (mapContext.isInitialized) {
            onShapesChanged();
        }
    }, [locations.stats.positive]);


    const checkAccess = (e) => {
        if ((!user || !user.id) && !project.universalAccessKey) {
            e.preventDefault();
            e.stopPropagation();

            window.location.href = '/auth/login';
        }
    }


    const onMapSearchControlChange = (lat, lng, additional) => {
        map.setView([lat, lng], MapHelper.defaultZoomLevelDetailed);

        if (!mapSearchedLocationRef.current) {
            mapSearchedLocationRef.current = L.marker([lat, lng]).addTo(map);
        } else {
            mapSearchedLocationRef.current.setLatLng([lat, lng]);
        }

        if (additional && additional.formatted_address) {
            let popupContent = '<div class="p-2">' + additional.formatted_address.filter((item) => item.length > 0).join('<br>') + '</div>';
            mapSearchedLocationRef.current.bindPopup(popupContent).openPopup();
        }
        /*if (locationData.bounds) {
            map.fitBounds(locationData.bounds);
        }*/
    }


    const onMapTypeControlChange = (layerName) => {
        const isEditable = (project.status === '00_in_progress') && !project.readOnly;

        setSelectedMapType(layerName);

        if (isEditable) {
            projectsModel.update(project.id, { map_type: layerName });
        }
    }


    const refreshLocations = (actualShapes) => {
        if (!unionShape.current) {return;}
        if (unionShape.current.bounds === {} || unionShape.current.bounds && !unionShape.current.bounds.isValid()) {
            locationsLoading.current = locationsLoading.current.filter((item) => item !== 'shapes');
            return;
        }
        if (locationsLoading.current.includes('locations')) {
            return;
        }

        locationsLoading.current.push('locations');

        let dataPoly = [];
        if (unionShape.current.shapes) {
            Object.values(unionShape.current.shapes).forEach((shape) => {
                if (!(shape.geoJSON && shape.geoJSON.geometry && shape.geoJSON.geometry.coordinates)) {return;}

                let shapeDryBounds = L.geoJSON(shape.geoJSON).getBounds();
                let shapeDryData = {
                    id: shape.id,
                    coordinates: shape.geoJSON.geometry.coordinates,
                    bounds: {
                        s: shapeDryBounds.getSouth(),
                        w: shapeDryBounds.getWest(),
                        n: shapeDryBounds.getNorth(),
                        e: shapeDryBounds.getEast(),
                    },
                    diameter: MapHelper.calculateBoundsDiameter(shapeDryBounds),
                };
                dataPoly.push(shapeDryData);
            });
        }

        let oversizedShapes = [];
        let coordsFetched = dataPoly.map((dataPolyItem) => {
            if (dataPolyItem.diameter > 50000) {
                notifications.notify('Shape "'+dataPolyItem.id+'" is too large to fetch coordinates.', 'warning');
                oversizedShapes.push(dataPolyItem.id);
                return;
            }

            return coordinatesModel.listPolygon({data: [dataPolyItem], project_id: projectId});
        });
        if (oversizedShapes.length) {
            let newShapes = {...actualShapes};
            oversizedShapes.forEach((shapeId) => {
                if (newShapes[shapeId]) {
                    delete newShapes[shapeId];
                }
            });
            setShapes(newShapes);
            actualShapes = newShapes;
        }
        Promise.all(coordsFetched).then((responses) => {
            // merge all responses in a single array
            let freshLocations = [];
            responses.forEach((response) => {
                if (response) {
                    freshLocations = freshLocations.concat(response);
                }
            });

            if (!freshLocations.length) {
                console.log('Error fetching coordinates.');
            } else {
                processFreshLocations(actualShapes, freshLocations);
            }
        }).finally(() => {
            locationsLoading.current = locationsLoading.current.filter((item) => item !== 'locations');
            locationsLoading.current = locationsLoading.current.filter((item) => item !== 'shapes');
        });
    }


    const processFreshLocations = (actualShapes, freshLocations) => {
        let fullLocationsPerShape = {},
            positiveLocations = [],
            positiveLocationsCount = 0,
            negativeLocations = [],
            negativeLocationsCount = 0,
            positiveLocationsPerShape = {},
            negativeLocationsPerShape = {};

        Object.keys(actualShapes).forEach((key) => {
            fullLocationsPerShape[key] = {
                positive: [],
                negative: [],
                counts: {
                    total: 0,
                    positive: 0,
                    negative: 0
                }
            };
            positiveLocationsPerShape[key] = 0;
            negativeLocationsPerShape[key] = 0;
        });

        let locationsProcessed = [],
            totalLocationsCount = 0
        freshLocations.forEach((location) => {
            let locationKeyParts = [location.lat, location.lng, location.filter], locationKey = locationKeyParts.join('|');
            let locationDuplicate = false,
                locationFilteredOut = false;

            if (locationFilters.length && !locationFilters.includes(parseInt(location.filter)) || !locationFilters.length) {
                // filter out by location type
                locationFilteredOut = true;
            }

            if (locationsProcessed.includes(locationKey)) {
                // duplicated location, count only for the shape
                locationDuplicate = true;
                return;
            } else {
                locationsProcessed.push(locationKey);
            }

            let shapesWithLocation = [], locationUnionType = true;

            Object.keys(actualShapes).forEach((key) => {
                // check if location is in this shape
                if (actualShapes[key].geoJSON && MapHelper.coordsInShape(L.latLng(location.lat, location.lng), actualShapes[key].geoJSON)) {
                    shapesWithLocation.push(key);

                    // check if this shape excludes this location
                    if (actualShapes[key].geoJSON.properties && (actualShapes[key].geoJSON.properties.union === false)) {
                        locationUnionType = false;
                    }
                }
            });

            let locProcessed = false;
            shapesWithLocation.forEach((key) => {
                //if (locProcessed) {return;}

                if (!locationFilteredOut) {
                    if (locationUnionType) {
                        positiveLocationsPerShape[key] += location.buildings_count;
                        fullLocationsPerShape[key].positive.push(location);
                        fullLocationsPerShape[key].counts.positive += location.buildings_count;
                        if (!locationDuplicate && !locProcessed) {
                            positiveLocationsCount += location.buildings_count;
                            positiveLocations.push(location);
                        }
                    } else {
                        negativeLocationsPerShape[key] += location.buildings_count;
                        fullLocationsPerShape[key].negative.push(location);
                        fullLocationsPerShape[key].counts.negative += location.buildings_count;
                        if (!locationDuplicate && !locProcessed) {
                            negativeLocationsCount += location.buildings_count;
                            negativeLocations.push(location);
                        }
                    }
                }
                if (!locationDuplicate && !locProcessed) {
                    totalLocationsCount += location.buildings_count;
                }

                if (!locationDuplicate) {
                    fullLocationsPerShape[key].counts.total += location.buildings_count;
                }

                locProcessed = true;
            });
        });

        setLocations({
            byShape: fullLocationsPerShape,
            positive: positiveLocations,
            negative: negativeLocations,
            stats: {
                total: totalLocationsCount,
                positive: positiveLocationsCount,
                negative: negativeLocationsCount,
                positiveLocationsPerShape: positiveLocationsPerShape,
                negativeLocationsPerShape: negativeLocationsPerShape ? negativeLocationsPerShape : 0
            }
        });
    }


    const onShapesChanged = (newShapes = null, shouldRefreshLocations = true, shouldUpdateProject = true) => {
        if (!unionShape.current) {return;}

        locationsLoading.current.push('shapes');
        unionShape.current.refresh(Object.values(newShapes ? newShapes : shapes));

        const data = {
            shapes: unionShape.current.shapes,
            union: unionShape.current.union,
            bounds: unionShape.current.bounds,
            locations: {
                total: locations.stats.total ? locations.stats.total : 0,
                positive: locations.stats.positive ? locations.stats.positive : 0,
                negative: locations.stats.negative ? locations.stats.negative : 0
            }
        };

        let projectUpdated = false;
        if (shouldUpdateProject) {
            if (project.universalAccessKey) {
                projectUpdated = projectsModel.uaUpdate(project.universalAccessKey, project.id, { shapes: data });
            } else {
                projectUpdated = projectsModel.update(project.id, { shapes: data });
            }
        } else {
            if (shouldRefreshLocations) {
                refreshLocations(data.shapes);
            } else {
                locationsLoading.current = locationsLoading.current.filter((item) => item !== 'shapes');
            }
        }

        if (projectUpdated !== false) {
            projectUpdated.then((response) => {
                if (shouldRefreshLocations) {
                    refreshLocations(data.shapes);
                }
            }).finally(() => {
                if (!shouldRefreshLocations) {
                    locationsLoading.current = locationsLoading.current.filter((item) => item !== 'shapes');
                }
            });
        }
    }


    const onAddShapeClick = (type) => {
        let newShapes = {...shapes};
        Object.keys(newShapes).forEach((key) => {
            newShapes[key].active = false;
            if (!newShapes[key].geoJSON) {
                delete newShapes[key];
            }
        });

        if (type) {
            let shape = {
                id: ShapeStyling.generateRandomId(),
                type: type,
                active: true,
            };
            newShapes[shape.id] = shape;
        }

        setShapes(newShapes);
    }


    const onShapeActiveChange = (id, active, geoJSON, layerData) => {
        if (active) {
            let newShapes = {...shapes};

            Object.keys(newShapes).forEach((key) => {
                if (newShapes[key].active === true) {
                    newShapes[key].active = false;
                }
            });

            newShapes[id].active = true;

            setShapes(newShapes);
        } else {
            let shapesEdited = false, coordinatesChanged = false;
            if (geoJSON && !_.isEqual(shapes[id].geoJSON, geoJSON)) {
                shapesEdited = true;

                if (shapes[id].geoJSON && !_.isEqual(shapes[id].geoJSON.geometry, geoJSON.geometry) || !shapes[id].geoJSON) {
                    coordinatesChanged = true;
                }
            }

            let newShapes = {...shapes};
            newShapes[id].active = false;
            if (geoJSON) {
                newShapes[id].geoJSON = geoJSON;
                newShapes[id].bounds = L.geoJSON(geoJSON).getBounds();
            }
            if (layerData) {newShapes[id].layerData = layerData;}

            setShapes(newShapes);

            if (shapesEdited) {
                onShapesChanged(newShapes, coordinatesChanged);
            }
        }
    }


    const onShapeDelete = (id) => {
        let newShapes = {...shapes};

        delete newShapes[id];

        setShapes(newShapes);
        onShapesChanged(newShapes);
    }


    const onShapeToggle = (id) => {
        let newShapes = {...shapes};

        if (newShapes[id] && newShapes[id].geoJSON && newShapes[id].geoJSON.properties) {
            if (newShapes[id].geoJSON.properties.union === false) {
                newShapes[id].geoJSON.properties.union = true;
            } else {
                newShapes[id].geoJSON.properties.union = false;
            }
        }

        setShapes(newShapes);
        onShapesChanged(newShapes);
    }


    const onShapeToggleVisible = (id) => {
        let newShapes = {...shapes};

        if (newShapes[id] && newShapes[id].geoJSON && newShapes[id].geoJSON.properties) {
            if (newShapes[id].geoJSON.properties.visible === false) {
                newShapes[id].geoJSON.properties.visible = true;
            } else {
                newShapes[id].geoJSON.properties.visible = false;
            }
        } else {
            newShapes[id].geoJSON.properties = { visible: false };
        }

        setShapes(newShapes);
    }


    const onShapeActivate = (id) => {
        onShapeActiveChange(id, true);

        if (shapes[id].bounds) {
            let newBounds = L.latLngBounds(shapes[id].bounds._southWest, shapes[id].bounds._northEast);
            if (newBounds.isValid()) {
                mapContext.map.fitBounds(newBounds);
            }
        }
    }


    const onMouseMove = (e) => {
        setCursorPosition(e.latlng);
    };


    const onShapesImported = (importedShapes, bounds) => {
        setShapes(importedShapes);

        if (bounds) {
            map.fitBounds(bounds);
        }
    }


    const onImportFromFiles = (fileId) => {
        shapesModel.view(fileId).then((response) => {
            if (response !== false && response.data) {
                let newShapeDraft = {
                    id: ShapeStyling.generateRandomId(),
                    type: MapHelper.detectShapeType(response.data),
                    geoJSON: response.data,
                    bounds: L.geoJSON(response.data).getBounds(),
                    layerData: {
                        imported: true
                    },
                    active: false,
                };

                let newShapes = {...shapes};
                newShapes[newShapeDraft.id] = newShapeDraft;
                onShapesChanged(newShapes, true);
                setShapes(newShapes);
                notifications.notify('File imported successfully', 'success');

                map.fitBounds(newShapeDraft.bounds);
            }
        });
    }


    const onRulerActive = (rulerAct) => {
        setRulerActive(rulerAct);
    };


    const showEmailReportModal = () => {
        if (projectInfo.title === 'Untitled Project') {
            notifications.notify('Please set a title for the project before finalising', 'warning');
            return;
        }

        setEmailReportModalShown(true);
    }


    const getStatusLabel = () => {
        if (!project || !project.status) {return '';}

        switch (project.status) {
            case '00_in_progress':
                return 'In Progress';
            case '10_processed':
                return 'Processed';
            case '20_archived':
                return 'Archived';
            default:
                return 'Canceled'
        }
    }


    const finalizeProject = () => {
        if (projectInfo.title === 'Untitled Project') {
            notifications.notify('Please set a title for the project before finalising', 'warning');
            return;
        }
        setPurchaseOrder('');
        setPurchaseOrderModalShown(true);
    }


    const finalizeConfirmedProject = () => {
        let newParams = project.params ? {... project.params} : {};
        newParams.purchase_order = purchaseOrder;

        projectsModel.update(project.id, { status: '10_processed', params: newParams }).then((response) => {
            if (response !== false) {
                notifications.notify('Project finalised successfully', 'success');
                window.location.reload();
            }
        });
    }


    const setFilters = (filter) => {
        const isEditable = (project.status === '00_in_progress') && !project.readOnly;

        let filterCode = [];
        switch (filter) {
            case 'house': filterCode = [1]; break;
            case 'business': filterCode = [2]; break;
            case 'not-yet-built': filterCode = [3, 4]; break;
        }

        let newFilters = [...locationFilters];
        filterCode.forEach((code) => {
            if (code === 3 || code === 4) {return;}

            if (newFilters.includes(code)) {
                newFilters = newFilters.filter((item) => item !== code);
            } else {
                newFilters.push(code);
            }
        });
        if (filterCode.includes(3) || filterCode.includes(4)) {
            if (newFilters.includes(3) || newFilters.includes(4)) {
                newFilters = newFilters.filter((item) => item !== 3 && item !== 4);
            } else {
                newFilters.push(3);
                newFilters.push(4);
            }
        }

        if (isEditable) {
            projectsModel.update(project.id, { location_filters: newFilters });
        }

        setLocationFilters(newFilters);
    }


    return (
        <div className={'map-panel ' + (rulerActive ? 'ruler-active' : '')}>
            <div id="map-container" ref={mapRef}></div>

            <div className={'map-area map-top'}>
                <MapSearchControlGetAddress address={''} onChange={onMapSearchControlChange} />
            </div>

            {project && project.data && (
            <div className={'map-area project-info'}>
                <OnMapInfoPanel className={'style-warning'}>
                    <MapInfoPanel
                        project={project}
                        type={'title'}
                        onValueChange={(value) => {
                            let newProjectInfo = {...projectInfo};
                            newProjectInfo.title = value;
                            setProjectInfo(newProjectInfo);
                        }}
                    >
                        <h1 className="h5 m-0">
                            {projectInfo.title}
                            <div className={'fs-1 mt-1 position-relative ' + (project.status !== '00_in_progress' ? 'text-danger' : 'text-muted')}>
                                Status: {getStatusLabel()}
                                {project.params && project.params.purchase_order ? (
                                    <span className="ms-2">PO: {project.params.purchase_order}</span>
                                ) : ''}

                                {locations.stats.positive > 0 ? (
                                <button onClick={() => {
                                        finalizeProject();
                                    }}
                                    className={'align-items-center border-0 btn d-flex position-absolute end-0 top-0 p-0' + ((project.status !== '00_in_progress' || project.readOnly) ? ' d-none' : '')}>
                                    <i className={'ti fs-4 ti-send'}></i>
                                    <span className={'fs-2 ms-2'}>Finalise the project</span>
                                </button>
                                ) : ''}
                            </div>
                            <div className="fs-1 mt-1 text-muted">ID: {project.id}</div>
                            <div className="fs-1 mt-1 text-muted">Shapes Version: {project.shapes_version}</div>

                            <button onClick={() => {
                                showEmailReportModal();
                            }} className={'align-items-center border-0 btn d-flex float-start mt-2 p-0' + ((!['00_in_progress', '10_processed'].includes(project.status) || project.readOnly) ? ' d-none' : '')}>
                                <i className={'ti fs-4 ti-mail'}></i>
                                <span className={'fs-2 ms-2'}>Email Report</span>
                            </button>

                            <button onClick={() => {
                                if (!infoPanelsMinified) {
                                    setAllShapesShown(false);
                                }
                                setInfoPanelsMinified(!infoPanelsMinified)
                            }} className="align-items-center border-0 btn d-flex float-end mt-2 p-0">
                                <i className={'ti fs-4 ' + (infoPanelsMinified ? 'ti-arrows-diagonal' : 'ti-arrows-diagonal-minimize-2')}></i>
                                <span className={'fs-2 ms-2'}>{infoPanelsMinified ? 'Maximize' : 'Minimize'} Panel</span>
                            </button>

                            <button onClick={() => {
                                onShapesChanged();
                                notifications.notify('Updating project information: counts may be changed!', 'warning');
                            }} className={'align-items-center border-0 btn d-flex float-end mt-2 me-3 p-0'+((project.status !== '00_in_progress' || project.readOnly) ? ' d-none' : '')}>
                                <i className={'ti fs-4 ti-refresh'}></i>
                                <span className={'fs-2 ms-2'}>Refresh Info</span>
                            </button>
                        </h1>
                    </MapInfoPanel>
                </OnMapInfoPanel>
                {infoPanelsMinified === false && (<>
                    {projectInfo.description && (
                        <OnMapInfoPanel className={'style-warning'}>
                            <MapInfoPanel
                                project={project}
                                type={'description'}
                                onValueChange={(value) => {
                                    let newProjectInfo = {...projectInfo};
                                    newProjectInfo.description = value;
                                    setProjectInfo(newProjectInfo);
                                }}
                            >
                                <div>{projectInfo.description.split('\n').map((str, index, array) =>
                                    ((index === array.length - 1) || (!str.length)) ? str :
                                        <p key={index} className={index < (array.length - 1) ? 'mb-1' : ''}>{str}</p>
                                )}</div>
                            </MapInfoPanel>
                        </OnMapInfoPanel>
                    )}
                    <OnMapInfoPanel className={'style-warning'}>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="">
                                Total mailing count: {locations.stats.positive} / {locations.stats.total} <Loading show={!!locationsLoading.current.length}/><br />
                                <span className={'d-flex flex-column fs-2 fw-semibold mt-2 text-muted' + ((project.status !== '00_in_progress' || project.readOnly) ? ' d-none' : '')}>
                                    <span>Filter properties by type:</span>

                                    {/*
                                    <div className="d-flex flex-row gap-3 fs-2">
                                        {[
                                            {key: 'house', index: 1, label: 'Residential'},
                                            {key: 'business', index: 2, label: 'Business'},
                                            {key: 'not-yet-built', index: 4, label: 'Not yet built'}
                                        ].map((item) => (
                                            <a
                                                key={item.key}
                                                href={'#'}
                                                className={(locationFilters.includes(item.index) ? 'text-success-emphasis' : 'text-body-tertiary text-decoration-underline')}
                                                onClick={(e) => {e.preventDefault(); setFilters(item.key);}}
                                            >{item.label}</a>
                                        ))}
                                    </div>*/}

                                    <div className="d-flex gap-2">
                                        <button
                                            className={'align-items-center border-0 btn d-flex p-0'}
                                            onClick={() => setFilters('house')}

                                            data-tooltip-content="Residential buildings"
                                            data-tooltip-place="right"
                                            data-tooltip-id="location-type-tooltips"
                                        >
                                            <i className={'ti fs-4 ti-home ' + (locationFilters.includes(1) ? 'text-warning' : 'opacity-50')}></i>
                                        </button>

                                        <button
                                            className={'align-items-center border-0 btn d-flex p-0'}
                                            onClick={() => setFilters('business')}

                                            data-tooltip-content="Business buildings"
                                            data-tooltip-place="right"
                                            data-tooltip-id="location-type-tooltips"
                                        >
                                            <i className={'ti fs-4 ti-building-store ' + (locationFilters.includes(2) ? 'text-warning' : 'opacity-50')}></i>
                                        </button>

                                        <button
                                            className={'align-items-center border-0 btn d-flex p-0'}
                                            onClick={() => setFilters('not-yet-built')}

                                            data-tooltip-content="Not yet built buildings"
                                            data-tooltip-place="right"
                                            data-tooltip-id="location-type-tooltips"
                                        >
                                            <i className={'ti fs-4 ti-wall ' + ((locationFilters.includes(4) || locationFilters.includes(3)) ? 'text-warning' : 'opacity-50')}></i>
                                        </button>
                                    </div>
                                </span>

                                <Tooltip id="location-type-tooltips"/>
                            </div>
                            <div className="">
                                {Object.keys(shapes).length} Shape(s)
                                <a href="#" className="ms-3" onClick={(e) => {
                                    e.preventDefault();
                                    setAllShapesShown(!allShapesShown);
                                }}>{allShapesShown ? 'Hide' : 'Show'}</a>
                            </div>
                        </div>
                    </OnMapInfoPanel>
                </>)}
                <ShapesListPanel
                    project={project}
                    visible={allShapesShown}
                    onShapeDelete={onShapeDelete}
                    onShapeActivate={onShapeActivate}
                    onShapeToggle={onShapeToggle}
                    onShapeToggleVisible={onShapeToggleVisible}
                    shapes={shapes}
                    locations={locations}
                    isEditable={(project.status === '00_in_progress') && !project.readOnly/* && user && user.id*/}
                />


                {rulerActive ? (
                    <OnMapInfoPanel className={'style-warning'}>
                        <MapWithMeasurement />
                    </OnMapInfoPanel>
                ) : ''}
            </div>
            )}
            <div className={'map-area map-corner-top-left'+(hideToolbar === true ? ' d-none' : '')} onClick={checkAccess}>
                <ImportExportControl
                    projectId={project.id}
                    project={project}
                    onShapesImported={onShapesImported}
                    shapes={shapes}
                    onImportFromFiles={onImportFromFiles}
                    isEditable={(project.status === '00_in_progress') && !project.readOnly/* && user && user.id*/}
                />


                <ShapesControl
                    project={project}
                    locations={locations}
                    shapes={shapes}
                    onShapesChanged={() => {onShapesChanged();}}
                    onAddShapeClick={onAddShapeClick}
                    onShapeActiveChange={onShapeActiveChange}
                    onShapeDelete={onShapeDelete}
                    checkAccess={checkAccess}
                    isEditable={(project.status === '00_in_progress') && !project.readOnly/* && user && user.id*/}
                    onRulerActive={onRulerActive}
                />
            </div>
            <div className={'map-area map-corner-top-right'}>
                <MapTypeControl
                    current={selectedMapType}
                    onChange={onMapTypeControlChange}
                />

                <ShapePanelControl
                    isEditable={(project.status === '00_in_progress') && !project.readOnly/* && user && user.id*/}
                    locations={locations}
                    shapes={shapes}
                    onShapesChanged={() => {onShapesChanged();}}
                    onAddShapeClick={onAddShapeClick}
                    onShapeActiveChange={onShapeActiveChange}
                    onShapeDelete={onShapeDelete}
                    checkAccess={checkAccess}
                />
            </div>
            <div className={'map-area map-corner-bottom-left'}>
                <OnMapInfoPanel data={`lat: ${cursorPosition.lat.toFixed(4)} lng: ${cursorPosition.lng.toFixed(4)}`} />
            </div>
            <div className={'map-area map-corner-bottom-right flex-row align-items-end'}>
                <UnitsToggle />

                <ZoomControl onZoomIn={() => {
                    map.setZoom(map.getZoom() + 1)
                }} onZoomOut={() => {
                    map.setZoom(map.getZoom() - 1)
                }} />
            </div>


            <EmailReportModal
                locations={locations}
                visible={emailReportModalShown}
                onCancel={() => {setEmailReportModalShown(false);}}
            />


            <SimpleModal
                visible={purchaseOrderModalShown}
                onClose={() => {setPurchaseOrderModalShown(false)}}
                modalContentClassName={'modal-filled'}
                headerTitle={null}
            >
                <div className="text-center pt-3">
                    <h4 className="mt-2">Purchase Order</h4>
                    <p className="mt-3">Please enter your purchase order reference to continue:</p>

                    <input type="text" className="form-control" value={purchaseOrder} onChange={(e) => setPurchaseOrder(e.target.value)} />

                    <div className="d-flex flex-row gap-3 my-2">
                        <button
                            type="button"
                            className="btn btn-light-danger text-danger"
                            onClick={() => {setPurchaseOrderModalShown(false)}}
                        >
                            Cancel
                        </button>

                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                finalizeConfirmedProject()
                            }}
                        >
                            Confirm and Continue
                        </button>
                    </div>

                </div>
            </SimpleModal>
        </div>
    );
}