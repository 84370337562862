import * as L from "leaflet";
import 'leaflet-measure-path';
import 'leaflet-measure-path/leaflet-measure-path.css';
import ShapeStyling from "./ShapeStyling";

import './shapes.css';
import React, {useEffect, useRef, useState} from "react";
import {useMap} from "../../../providers/MapProvider";
import MapHelper from "../../../helpers/MapHelper";
import _ from "lodash";
import "./ShapePopup.css";
import ShapeInfoPanel from "../general/ui/ShapeInfoPanel";
import Locations from "../locations/Locations";
import {useApi} from "../../../providers/ApiProvider";
import ShapesModel from "../../../models/ShapesModel";
import {useNotifications} from "../../../providers/NotificationsProvider";
import * as turf from "@turf/turf";
import ShapeNotesDisplay from "./ShapeNotesDisplay";
import {createRoot} from "react-dom/client";
import MarkerPopup from "../locations/MarkerPopup";

export default function Shape({id, data, active = false, visible = true, locations = {}, onActiveChange, onSelfDelete, onCreateFinished, onShapeChange, isEditable = false}) {
    const mapContext = useMap();
    const notifications = useNotifications();

    const apiContext = useApi();
    const shapesModel = new ShapesModel(apiContext.api);

    let baseUnion = data.geoJSON && data.geoJSON.properties && data.geoJSON.properties.union ? data.geoJSON.properties.union : true;
    const [union, setUnion] = useState(baseUnion);

    let baseNotes = data.geoJSON && data.geoJSON.properties && data.geoJSON.properties.notes ? data.geoJSON.properties.notes : null;
    const [notes, setNotes] = useState(baseNotes);

    let baseCustomColor = data.geoJSON && data.geoJSON.properties && data.geoJSON.properties.color ? data.geoJSON.properties.color : null;
    const [customColor, setCustomColor] = useState(baseCustomColor);
    const [layer, setLayer] = useState(null);
    const layerRef = useRef(layer);
    const [initComplete, setInitComplete] = useState(false);
    const [drawingNewShape, setDrawingNewShape] = useState(true);

    let baseClusterified = true;
    if (data.geoJSON && data.geoJSON.properties && (data.geoJSON.properties.clusterified === false)) {
        baseClusterified = false;
    }
    const [clusterified, setClusterified] = useState(baseClusterified);

    const [infoPanelMinimized, setInfoPanelMinimized] = useState(false);

    let basePinsVisible = data.geoJSON && data.geoJSON.properties && data.geoJSON.properties.pinsVisible ? data.geoJSON.properties.pinsVisible : false;
    const [pinsVisible, setPinsVisible] = useState(basePinsVisible);

    const [dataLastUpdated, setDataLastUpdated] = useState(null);

    const getShapeVisibleFromData = (newData) => {
        if (!newData) {newData = data;}
        let baseVisible = true;
        if (newData.geoJSON && newData.geoJSON.properties && newData.geoJSON.properties.visible === false) {
            baseVisible = false;
        }
        return baseVisible;
    }
    let baseShapeVisible = getShapeVisibleFromData();
    const [shapeVisible, setShapeVisible] = useState(baseShapeVisible);

    const getTitleFromData = () => {
        let baseTitle = 'Shape';
        if (data.type === 'Marker') {
            baseTitle = 'Marker Pin';
        }
        if (data.geoJSON && data.geoJSON.properties && data.geoJSON.properties.title) {
            baseTitle = data.geoJSON.properties.title;
        } else if (data.geoJSON && data.geoJSON && data.geoJSON.geometry && data.geoJSON.geometry.type) {
            baseTitle += ' ' + data.geoJSON.geometry.type;
        }
        return baseTitle;
    }
    let baseTitle = getTitleFromData();
    const [title, setTitle] = useState(baseTitle);

    let userInfo = JSON.parse(localStorage.getItem('user')), unitsType = '';
    if (userInfo && userInfo.settings && userInfo.settings.units && userInfo.settings.units === 'imperial') {
        unitsType = 'imperial';
    } else if (userInfo && userInfo.settings && userInfo.settings.units && userInfo.settings.units === 'metric') {
        unitsType = 'metric';
    }
    let baseDrawingOptions = {... ShapeStyling.DRAWING_OPTIONS_POSITIVE};
    if (!baseUnion) {baseDrawingOptions = {... ShapeStyling.DRAWING_OPTIONS_NEGATIVE};}
    if (unitsType) {
        baseDrawingOptions = {...baseDrawingOptions, pathOptions: {...baseDrawingOptions.pathOptions, showMeasurements: true, measurementOptions: {imperial: unitsType === 'imperial'}}};
        baseDrawingOptions = {...baseDrawingOptions, hintlineStyle: {...baseDrawingOptions.hintlineStyle, showMeasurements: true, measurementOptions: {imperial: unitsType === 'imperial'}}};
        baseDrawingOptions = {...baseDrawingOptions, templineStyle: {...baseDrawingOptions.templineStyle, showMeasurements: true, measurementOptions: {imperial: unitsType === 'imperial'}}};
    }
    if (customColor) {
        let newPathOptions = ShapeStyling.applyCustomColor(baseDrawingOptions.pathOptions, customColor);
        baseDrawingOptions = {...baseDrawingOptions, pathOptions: newPathOptions};
    }
    const [drawingOptions, setDrawingOptions] = useState(baseDrawingOptions);

    const onActiveChangeRef = useRef(onActiveChange);
    const onSelfDeleteRef = useRef(onSelfDelete);
    const onCreateFinishedRef = useRef(onCreateFinished);


    useEffect(() => {
        onActiveChangeRef.current = onActiveChange;
    }, [onActiveChange]);
    useEffect(() => {
        onSelfDeleteRef.current = onSelfDelete;
    }, [onSelfDelete]);
    useEffect(() => {
        onCreateFinishedRef.current = onCreateFinished;
    }, [onCreateFinished]);


    useEffect(() => {
        layerRef.current = layer;
    }, [layer]);


    useEffect(() => {
        if (mapContext.isInitialized && !initComplete) {
            init();
        }

        return selfDelete;
    }, []);


    useEffect(() => {
        if (mapContext.isInitialized && initComplete) {
            if (!customColor && layer) {
                let newColor;

                if (union) {
                    newColor = ShapeStyling.COLOR_POSITIVE;
                } else {
                    newColor = ShapeStyling.COLOR_NEGATIVE;
                }
                let newPathOptions = ShapeStyling.applyCustomColor(drawingOptions.pathOptions, newColor);
                let newHintlineStyle = ShapeStyling.applyCustomColor(drawingOptions.hintlineStyle, newColor);
                let newTemplineStyle = ShapeStyling.applyCustomColor(drawingOptions.templineStyle, newColor);

                if (data.type !== 'Marker') {
                    layer.setStyle(newPathOptions);
                }
                setDrawingOptions({...drawingOptions, pathOptions: newPathOptions, hintlineStyle: newHintlineStyle, templineStyle: newTemplineStyle});
            }

            if (union !== undefined && data && data.geoJSON && data.geoJSON.properties && union !== data.geoJSON.properties.union) {
                onShapeChange(id, union);
            }
        }
    }, [union]);


    useEffect(() => {
        if (layer) {
            if (shapeVisible) {
                layer.addTo(mapContext.map);
                enable();
            } else {
                mapContext.map.removeLayer(layer);
            }

            if (shapeVisible !== undefined && data && data.geoJSON && data.geoJSON.properties && union !== data.geoJSON.properties.visible) {
                onShapeChange(id, union, !!shapeVisible);
            }
        }
    }, [shapeVisible]);


    useEffect(() => {
        if (mapContext.isInitialized && initComplete) {
            let newPathOptions = ShapeStyling.applyCustomColor(drawingOptions.pathOptions, customColor);
            let newHintlineStyle = ShapeStyling.applyCustomColor(drawingOptions.hintlineStyle, customColor);
            let newTemplineStyle = ShapeStyling.applyCustomColor(drawingOptions.templineStyle, customColor);
            if (data.type !== 'Marker') {
                layer.setStyle(newPathOptions);
            } else {
                let newMarkerStylesIcon = L.divIcon({
                    className: 'leaflet-loc-marker',
                    html: '<svg style="--loc-marker-color: '+customColor+';" height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m32 0a24.0319 24.0319 0 0 0 -24 24c0 17.23 22.36 38.81 23.31 39.72a.99.99 0 0 0 1.38 0c.95-.91 23.31-22.49 23.31-39.72a24.0319 24.0319 0 0 0 -24-24zm0 35a11 11 0 1 1 11-11 11.0066 11.0066 0 0 1 -11 11z"/></g></svg>',
                    iconSize: [64, 64],
                    iconAnchor: [32, 64],
                    popupAnchor: [0, -69],
                });
                layer.setIcon(newMarkerStylesIcon);
            }

            setDrawingOptions({...drawingOptions, pathOptions: newPathOptions, hintlineStyle: newHintlineStyle, templineStyle: newTemplineStyle});
        }
    }, [customColor]);


    useEffect(() => {
        if (data.geoJSON && data.geoJSON.properties && data.geoJSON.properties.union !== union) {
            setUnion(data.geoJSON.properties.union);
        }

        let newTitle = getTitleFromData();
        if (newTitle !== title) {
            setTitle(newTitle);
        }

        let newShapeVisible = getShapeVisibleFromData(data);
        if (newShapeVisible !== shapeVisible) {
            setShapeVisible(newShapeVisible);
        }
    }, [data]);


    useEffect(() => {
        let newShapeVisible = getShapeVisibleFromData(data);
        if (newShapeVisible !== shapeVisible) {
            setShapeVisible(newShapeVisible);
        }
    }, [visible]);


    useEffect(() => {
        if (!mapContext.isInitialized || !initComplete) {return;}

        if (active) {
            enable();
        } else {
            disable();
        }
    }, [active]);


    useEffect(() => {
        if (locations) {
            setDataLastUpdated(new Date());
        } else {
            setDataLastUpdated(null);
        }
    }, [locations]);


    const toggleUnion = () => {
        setUnion(!union);
    }


    const selfDelete = () => {
        mapContext.map.pm.disableDraw();

        if (layerRef.current) {mapContext.map.removeLayer(layerRef.current);}

        onActiveChangeRef.current = null;

        onSelfDeleteRef.current(id);
    }


    const _getDryShape = (currentLayer) => {
        if (!layer) {return {
            geoJSON: null,
            layerData: null
        };}
        let geoJSON, layerData;

        if (typeof layer.getRadius === 'function') {
            geoJSON = MapHelper.circleToPolygon(currentLayer);
            layerData = {
                center: currentLayer.getLatLng(),
                radius: currentLayer.getRadius()
            };

            geoJSON.properties['radius'] = currentLayer.getRadius();
        } else if (data.type === ShapeStyling.TYPE_POINT) {
            geoJSON = currentLayer.toGeoJSON();
            layerData = L.latLngBounds(currentLayer.getLatLng(), currentLayer.getLatLng());
        } else if (data.type === ShapeStyling.TYPE_POLYLINE) {
            geoJSON = currentLayer.toGeoJSON();
            layerData = currentLayer.getBounds();
        } else {
            geoJSON = currentLayer.toGeoJSON();
            layerData = currentLayer.getBounds();
        }

        if (geoJSON.type === 'FeatureCollection' && (geoJSON.features.length === 1)) {
            geoJSON = geoJSON.features[0];
        }

        geoJSON = JSON.parse(JSON.stringify(geoJSON));
        if (!geoJSON.properties) {geoJSON.properties = {};}
        if (customColor) {geoJSON.properties['color'] = customColor;}
        if (notes) {geoJSON.properties['notes'] = notes;}
        if (title) {geoJSON.properties['title'] = title;}
        geoJSON.properties['union'] = union;
        geoJSON.properties['visible'] = shapeVisible;
        geoJSON.properties['clusterified'] = clusterified;
        geoJSON.properties['pinsVisible'] = pinsVisible;
        geoJSON.properties['id'] = id;

        return {
            geoJSON: geoJSON,
            layerData: layerData
        }
    }


    const initSave = (noChecks = false) => {
        let dryShapeData = _getDryShape(layer);

        if (active || !_.isEqual(data.geoJSON, dryShapeData.geoJSON) || noChecks) {
            onActiveChangeRef.current(id, false, dryShapeData.geoJSON, dryShapeData.layerData);
        }
    }


    const onInitRefresh = () => {
        onShapeChange(id, union, shapeVisible, true);
    }


    const onSaveToFiles = () => {
        shapesModel.create({data: data.geoJSON, name: title}).then((response) => {
            notifications.notify('Shape saved successfully', 'success');
        });
    }


    const disable = () => {
        if (!onActiveChangeRef.current) {return;}

        if (!initComplete) {
            selfDelete();
        } else {
            layer.pm.disable();
            mapContext.map.pm.disableDraw();
        }

        initSave();
    }


    const enable = () => {
        if (!layer) {return;}

        if (isEditable) {
            layer.pm.enable({
                allowSelfIntersection: false,
            });
        }

        /*layer.on('pm:edit', () => {
            const text = getMeasurements(layer);
            // get popup
            let popup = layer.getPopup();
            if (!popup) {
                popup = L.popup();
            }
            popup.setContent('<div>'+text.join('<br>')+'</div>');
            layer.bindPopup(popup);
            layer.openPopup();
        });*/

        mapContext.map.once('click', () => {
            //console.log('click 2');
            if (typeof onActiveChangeRef.current !== 'function') {return;}

            onActiveChangeRef.current(id, false)
        });
    }


    const onTitleChange = (title) => {
        setTitle(title);

        initSave();
    }


    const onNotesChange = (notes) => {
        setNotes(notes);

        initSave();
    }


    const init = () => {
        if (data.layerData) {
            loadShape();
            setDrawingNewShape(false);
        } else {
            if (active && data.type) {
                let customDrawingOptions = {...drawingOptions};
                if (!customColor) {
                    const newCustomColor = ShapeStyling.generateRandomColor();
                    setCustomColor(newCustomColor);
                    customDrawingOptions = ShapeStyling.applyCustomColor(customDrawingOptions, newCustomColor);
                }

                mapContext.map.pm.enableDraw(data.type, customDrawingOptions);
                //console.log('enabled');
                setDrawingNewShape(true);
            }
            mapContext.map.once('pm:create', (e) => {
                if (drawingNewShape) {
                    setDrawingNewShape(false);
                }
                e.layer.on('click', (ev) => {
                    //console.log('click 1');
                    if (!mapContext.map.pm.globalDrawModeEnabled()) {
                        L.DomEvent.preventDefault(ev);
                        L.DomEvent.stopPropagation(ev);

                        onActiveChangeRef.current(id, true);
                    }
                });

                if (data.type === ShapeStyling.TYPE_POLYLINE) {
                    let bufferShape = getBufferShape(e.layer.toGeoJSON(), 1000);
                    let bufferedLayer = L.geoJSON(bufferShape, drawingOptions);
                    bufferedLayer.addTo(mapContext.map);
                }

                setLayer(e.layer);
                setInitComplete(true);

                let dryShapeData = _getDryShape(e.layer);
                if (onActiveChangeRef.current) {
                    onActiveChangeRef.current(id, false, dryShapeData.geoJSON, dryShapeData.layerData);
                }
                onCreateFinishedRef.current();
            });
        }
    }


    const loadShape = () => {
        let layer;
        if (data.geoJSON && data.geoJSON.type === 'FeatureCollection' && (data.geoJSON.features.length === 1)) {
            data.geoJSON = data.geoJSON.features[0];
        }

        if (data.type === ShapeStyling.TYPE_CIRCLE) {
            layer = L.circle(data.layerData.center, {...drawingOptions.pathOptions, radius: data.layerData.radius});
        } else if (data.type === ShapeStyling.TYPE_RECTANGLE) {
            let coordinates = data.geoJSON.geometry.coordinates[0];
            const bounds = L.latLngBounds([coordinates[0][1], coordinates[0][0]], [coordinates[2][1], coordinates[2][0]]);

            layer = L.rectangle(bounds, drawingOptions.pathOptions);
        } else if (data.type === ShapeStyling.TYPE_POLYGON) {
            layer = L.geoJSON(data.geoJSON, drawingOptions.pathOptions);
        } else if (data.type === ShapeStyling.TYPE_POINT) {
            let newMarkerStylesIcon = L.divIcon({
                className: 'leaflet-loc-marker',
                html: '<svg style="--loc-marker-color: '+customColor+';" height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m32 0a24.0319 24.0319 0 0 0 -24 24c0 17.23 22.36 38.81 23.31 39.72a.99.99 0 0 0 1.38 0c.95-.91 23.31-22.49 23.31-39.72a24.0319 24.0319 0 0 0 -24-24zm0 35a11 11 0 1 1 11-11 11.0066 11.0066 0 0 1 -11 11z"/></g></svg>',
                iconSize: [64, 64],
                iconAnchor: [32, 64],
                popupAnchor: [0, -69],
            });
            layer = L.marker(data.geoJSON.geometry.coordinates.toReversed(), {icon: newMarkerStylesIcon});

            // bind a popup, open it
            const container = document.createElement('div');
            container.classList.add('leaflet-loc-popup-content-wrapper');
            const root = createRoot(container);
            root.render(<ShapeNotesDisplay initialJSON={notes} />);

            layer.bindPopup(notes ? container : 'No notes.', { closeOnClick: false, autoClose: false });
            layer.openPopup();
        } else if (data.type === ShapeStyling.TYPE_POLYLINE) {
            layer = L.geoJSON(data.geoJSON, drawingOptions.pathOptions);
        } else {
            return false;
        }

        if (isEditable) {
            layer.pm.enable(drawingOptions);
        }
        if (shapeVisible) {
            layer.addTo(mapContext.map);
            if (data.type === ShapeStyling.TYPE_POINT) {
                layer.openPopup();
            }
        }


        layer.on('click', (e) => {
            //console.log('click 3');
            // check if drawing is enabled
            if (!mapContext.map.pm.globalDrawModeEnabled()) {
                L.DomEvent.preventDefault(e);
                L.DomEvent.stopPropagation(e);

                onActiveChangeRef.current(id, true);
            }
        });

        setLayer(layer);

        setInitComplete(true);
    }


    const onPanelMinimizeChange = (minimized) => {
        setInfoPanelMinimized(minimized);
    }


    const onToggleClusterified = () => {
        if (!pinsVisible && clusterified) {
            onPinsVisibleChange(!pinsVisible);
        }

        setTimeout(() => {
            setClusterified(!clusterified);
        }, 1000);
    }


    const onPinsVisibleChange = (visible) => {
        setPinsVisible(visible);
    }


    const onLayerToTop = () => {
        if (!layer) {return;}

        layer.bringToFront();
    }


    const onLayerToBottom = () => {
        if (!layer) {return;}

        layer.bringToBack();
    }


    const onToggleVisible = (newVisible) => {
        setShapeVisible(newVisible);
    }


    const getBufferShape = (shape, padding) => {
        return turf.buffer(shape, padding, {units: 'meters'});
    }


    return (<>
        {active && !drawingNewShape ? (
            <ShapeInfoPanel
                id={id}
                data={data}
                title={title}
                locations={locations}
                dataLastUpdated={dataLastUpdated}
                isEditable={isEditable}

                onTitleChange={onTitleChange}
                toggleUnion={toggleUnion}
                setCustomColor={setCustomColor}
                selfDelete={selfDelete}
                onNotesChange={onNotesChange}

                onInitRefresh={onInitRefresh}
                onSaveToFiles={onSaveToFiles}

                onLayerToTop={onLayerToTop}
                onLayerToBottom={onLayerToBottom}
                onToggleVisible={onToggleVisible}

                pinsVisible={pinsVisible}
                onPinsVisibleChange={onPinsVisibleChange}

                panelMinimize={infoPanelMinimized}
                onPanelMinimizeChange={onPanelMinimizeChange}

                clusterified={clusterified}
                onToggleClusterified={onToggleClusterified}
            />
        ) : null}

        {pinsVisible ? (
            <Locations
                locationsByShape={locations}
                shapeColor={customColor}
                clusterified={clusterified}
            />
        ) : null}
    </>);
}